interface OpenGraphAttributes {
  [key: string]: string
}

interface OpenGraphDescriptor {
  property: string
  content: string
}

class OpenGraphBuilder {
  private readonly attributes: OpenGraphAttributes

  constructor() {
    this.attributes = {
      'og:type': 'website',
    }
  }

  title(title: string): OpenGraphBuilder {
    this.attributes['og:title'] = title
    return this
  }

  description(description: string): OpenGraphBuilder {
    this.attributes['og:description'] = description
    return this
  }

  image(image: string): OpenGraphBuilder {
    this.attributes['og:image'] = image
    return this
  }

  url(url: string): OpenGraphBuilder {
    this.attributes['og:url'] = url
    return this
  }

  type(type: string): OpenGraphBuilder {
    this.attributes['og:type'] = type
    return this
  }

  build(): OpenGraphDescriptor[] {
    return Object.entries(this.attributes).map(([key, value]) => ({
      property: key,
      content: value,
    }))
  }
}

export function og(): OpenGraphBuilder {
  return new OpenGraphBuilder()
}
