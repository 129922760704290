import type { MetaFunction } from '@remix-run/node'
import { unstable_defineLoader as defineLoader } from '@remix-run/node'

import { authenticator } from '~/auth.server'
import spiralCalendarLarge from '~/images/spiral-512.png'
import spiralCalendar from '~/images/spiral-calendar.png'
import { createTitle } from '~/title'
import { Button } from '~/ui/button'
import { Link } from '~/ui/link'
import { og } from '~/utils/open-graph'

export const meta: MetaFunction = () => {
  const title = createTitle()
  const description =
    'A little app for keeping track of dates - birthdays, anniversaries, and more.'

  return [
    { title },
    { name: 'description', content: description },
    ...og()
      .title(title)
      .description(description)
      .image(
        new URL(spiralCalendarLarge, 'https://keeptrackof.date').toString()
      )
      .build(),
  ]
}

export const loader = defineLoader(async ({ request }) => {
  await authenticator.isAuthenticated(request, {
    successRedirect: '/events/upcoming',
  })

  return {}
})

export default function Index() {
  return (
    <>
      <header>
        <nav
          className='mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8'
          aria-label='Global'
        >
          <div className='flex flex-1'>
            <Link href='/' className='-m-1.5 p-1.5'>
              <h1 className='flex items-center space-x-2 text-2xl font-bold text-gray-900 dark:text-zinc-100'>
                <img src={spiralCalendar} alt='' className='h-8 w-auto' />
                <span>Keep Track of Date</span>
              </h1>
            </Link>
          </div>
          <div className='flex flex-1 flex-row items-center justify-end gap-x-4'>
            <Button href='/login' plain>
              Log in
            </Button>
            <Button href='/sign-up' color='primary'>
              Sign up
            </Button>
          </div>
        </nav>
      </header>
    </>
  )
}
